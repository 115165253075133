import React from 'react';
import '../App.css';

function SuccessPage() {
    return (
        <>
            <div className="Page-header" >
            </div>
            <div className="Page-content">
                <h1 style={{color: "#388e3c"}}>Payment Successful!</h1>
                <div className='NoticeBoard'>
                    <p>Your transaction has been completed successfully. Thank you for your purchase!</p>
                    <p style={{marginTop: "20px"}}>
                        You can return to the <a href="/Homepage">Homepage</a> or visit your
                        <a href="/Account"> Account</a> to view updated account information.
                    </p>
                </div>
            </div>
        </>
    );
}

export default SuccessPage;