import React, { useEffect, useState } from "react";
import axios from "axios";
import EssayList from "../../components/ManageEssayEssayList";
import EssayEditor from "../../components/ManageEssayEssayEditor";
import AddFolderDialog from "../../components/ManageEssayAddFolderDialog";
import ConfirmDeleteDialog from "../../components/ManageEssayConfirmDeleteDialog";
import "../../App.css"; // Add custom styles here if needed
import {
    getSessionStorageItem,
    updateSessionData,
    setSessionStorageItem,
    removeSessionStorageItem,
} from "../../components/Mask";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ManageWritingAccount = () => {
    const [membership, setMembership] = useState(getSessionStorageItem("membership") || "");
    const [role, setRole] = useState(getSessionStorageItem("role" || ""));
    const [quota, setQuota] = useState(getSessionStorageItem("quota" || 0));
    const [essays, setEssays] = useState([]); // Stores list of essays
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [accountErrorMessage, setAccountErrorMessage] = useState(null);
    const [selectedEssay, setSelectedEssay] = useState(null); // Essay selected for editing
    const [pagination, setPagination] = useState({ page: 1, limit: 10 }); // Pagination state
    const [totalEssays, setTotalEssays] = useState(0); // Total essays count
    const [greeting, setGreeting] = useState("");

    // Fetch user account info and essays
    const fetchAccountInfo = async () => {
        setLoading(true);
        try {
            let accountNeedsUpdate = !membership || !role;

            if (accountNeedsUpdate) {
                const userSession = await axios.get(`${API_BASE_URL}/user_session`, { withCredentials: true });
                if (process.env.NODE_ENV === 'development') {
                    console.log("User session data:", userSession);
                }

                const { membership: retrievedMembership, role: retrievedRole, quota: retrievedQuota } = userSession.data;

                updateSessionData("membership", retrievedMembership, setMembership);
                updateSessionData("role", retrievedRole, setRole);
                updateSessionData("quota", retrievedQuota, setQuota);

                // Use retrieved values instead of relying on asynchronous state updates
                if (!retrievedMembership || !retrievedRole) {
                    setAccountErrorMessage("The session is not found. Please log in with a valid account.");
                    return;
                }

                // Use local variables for membership and role
                accountNeedsUpdate = false;
            }

            if (!accountNeedsUpdate && membership && role) {
                if (quota < 2) {
                    setAccountErrorMessage("You need a premium membership (Silver or Gold) for this service.");
                    return;
                }

                const essaysResponse = await axios.get(`${API_BASE_URL}/essays`, {
                    params: { page: pagination.page, limit: pagination.limit },
                    withCredentials: true,
                });

                setEssays(essaysResponse.data.data);
                setTotalEssays(essaysResponse.data.total);
                if (process.env.NODE_ENV === 'development') {
                    console.log("Fetched essays:", essaysResponse.data.data);
                }

                const greetingMessage = `As a premium user with ${membership} membership, you can save up to ${quota}
                    essays, including their evaluation reports (if any), in your account. At this moment, there are
                    ${essaysResponse.data.total} essays in your account. They are presented below, sorted by their
                    essay tags.`;
                setGreeting(greetingMessage);
            }
        } catch (error) {
            setError(error.response?.data?.detail || "Failed to load data");
        } finally {
            setLoading(false);
        }
    };

    // Fetch data when component mounts or pagination changes
    useEffect(() => {
        fetchAccountInfo();
    }, [pagination]);

    // Handlers for pagination
    const handleNextPage = () => {
        if (pagination.page * pagination.limit < totalEssays) {
            setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
        }
    };

    const handlePrevPage = () => {
        if (pagination.page > 1) {
            setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
        }
    };

    // Handlers for dialogs
    const handleEditEssay = (essay) => {
        if (process.env.NODE_ENV === 'development') {
            console.log("Editing essay:", essay);
        }
        setSelectedEssay(essay);
    };

    // Refresh essays after any operation
    const refreshEssays = () => {
        setLoading(true);
        fetchAccountInfo();
    };

    return (
        <>
            <h1>Manage Writing Account</h1>
            <p style={{marginTop: "1.5em", marginBottom: "0px", marginLeft: "1em", marginRight: "1em"}}>
                For <strong>premium users</strong> with membership of <strong>Silver</strong> or <strong>Gold</strong>,
                you can manage your writing account here. Available functions include:
            </p>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "0.5em",
                    width: "23.5em",
                    marginLeft: "1em",
                }}
            >
                <div style={{ flex: 1 }}>
                    <p style={{ marginTop: '0px', marginBottom: "0.5em" }}>
                        Uploading essays
                    </p>
                    <p style={{ marginTop: '0px', marginBottom: "0.5em" }}>
                        Deleting essays
                    </p>
                </div>
                <div style={{ flex: 1.2 }}>
                    <p style={{ marginTop: '0px', marginBottom: "0.5em" }}>
                        Editing essay tags
                    </p>
                    <p style={{ marginTop: '0px', marginBottom: "0.5em" }}>
                        Adding/Editing comments
                    </p>
                </div>
            </div>
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: "red" }}>{error}</p>}
            {accountErrorMessage && (
                <p style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px', color: 'red'}}>
                    {accountErrorMessage}
                </p>
            )}
            {greeting &&
                <p style={{marginLeft: "20px", marginRight: "20px"}}>
                    {greeting}
                </p>
            }
            <div className="actions">
                <button className="btn-broad-action" onClick={refreshEssays}>Refresh Essay Records</button>
            </div>
            <EssayList
                essays={essays}
                onEdit={handleEditEssay}
            />
            <div className="pagination">
                <button
                    onClick={handlePrevPage}
                    className="btn-record-action"
                    style={{marginRight: "10px"}}
                    disabled={pagination.page === 1}
                >
                    Previous
                </button>
                <span>
                    Page {pagination.page} of {Math.ceil(totalEssays / pagination.limit)}
                </span>
                <button
                    onClick={handleNextPage}
                    className="btn-record-action"
                    style={{marginLeft: "10px"}}
                    disabled={pagination.page * pagination.limit >= totalEssays}
                >
                    Next
                </button>
            </div>

            {/* Modals */}
            {selectedEssay && (
                <EssayEditor
                    essay={selectedEssay}
                    onClose={() => setSelectedEssay(null)}
                    onSaveSuccess={refreshEssays}
                />
            )}
        </>
    );
};

export default ManageWritingAccount;
