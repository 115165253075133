// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { SettingsProvider, SettingsContext } from './components/SettingsContext';
import useWindowSize from './components/UseWindowSize'; // Hook for window size tracking
import Sidebar from './Sidebar';
import HamburgerMenu from './HamburgerMenu';
import './App.css';
import logo from './assets/images/icon01-512X512.png';
import Home from './pages/Homepage';
import WritingAssistance from './pages/WritingAssistance';
import ProductsServices from './pages/ProductsServices';
import HelpSupport from './pages/HelpSupport';
import Account from './pages/Account';
import SuccessPage from './pages/SuccessPage';
import CancelPage from './pages/CancelPage';
import { setSessionStorageItem, getSessionStorageItem, removeSessionStorageItem } from "./components/Mask";

function App() {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const windowWidth = useWindowSize();

    // Map fontSize to numeric pixel values
    const fontSizeMap = {
        'very small': 12,
        small: 14,
        medium: 16,
        large: 18,
        'very large': 20,
    };

    // Get the dynamic font size in pixels (default to medium if undefined)
    const fontSizeKey = getSessionStorageItem("font_size") || 'medium';
    const fontSize = fontSizeMap[fontSizeKey] || fontSizeMap['medium'];

    // Calculate responsive breakpoint dynamically
    const responsiveBreakpoint = 110 * fontSize;


    // Screen size breakpoints
    const isSmallScreen = windowWidth <= 768; // Small screens: triggers HamburgerMenu
    const isModeratelySmall = windowWidth > 768 && windowWidth <= responsiveBreakpoint; // Responsive breakpoint
    const isLargeScreen = windowWidth > responsiveBreakpoint; // Large screens: Sidebar fully functional

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const handleTokenChange = (newToken) => {
        if (newToken) setSessionStorageItem('token', newToken);
        else removeSessionStorageItem('token');
    };

    const handleLinkClick = () => setSidebarOpen(false);

    return (
        <SettingsProvider>
            <Router>
                <div className="App">
                    {/* Hamburger Menu for Small Screens */}
                    {isSmallScreen && <HamburgerMenu toggleSidebar={toggleSidebar} />}

                    {/* Sidebar */}
                    <Sidebar
                        isOpen={isSidebarOpen}
                        onTokenChange={handleTokenChange}
                        onLinkClick={handleLinkClick}
                        hideHeader={isModeratelySmall} // Pass prop to hide sidebar header
                    />

                    {/* Main Content Area */}
                    <div className="MainContent">
                        {/* Page Tabs Area */}
                        <div className="Page-header">
                            <div className="Page-tabs">
                                {(isSmallScreen || isModeratelySmall) && (
                                    <div className="Page-tabs-header">
                                        <img src={logo} alt="SophoScriptor Logo" className="Page-tabs-logo" />
                                        <span className="Page-tabs-title">SophoScriptor</span>
                                    </div>
                                )}
                                {/* Add Tab Links Here */}
                            </div>
                        </div>

                        {/* Routing Logic */}
                        <Routes>
                            <Route path="/Success/*" element={<SuccessPage />} />
                            <Route path="/Cancel/*" element={<CancelPage />} />
                            <Route path="/Homepage" element={<Home />} />
                            <Route path="/WritingAssistance/*" element={<WritingAssistance />} />
                            <Route path="/ProductsServices/*" element={<ProductsServices />} />
                            <Route path="/HelpSupport/*" element={<HelpSupport />} />
                            <Route path="/Account/*" element={<Account onTokenChange={handleTokenChange} />} />
                            <Route path="/" element={<Navigate to="/Homepage" />} />
                            <Route path="*" element={<Navigate to="/Homepage" />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        </SettingsProvider>
    );
}

export default App;


