import React, { useState, useEffect, useRef } from 'react';

/**
 * A reusable progress circle that increments from 0% (or from wherever it is)
 * up to actualStatus, slowing down as it nears the target and pass over the target up to +10.
 *
 * Props:
 *  - actualStatus (number): the current progress from backend (0-100)
 *  - baseSpeed (number): the fastest animation interval in ms (e.g., 100, 300)
 *  - onComplete (function): optional callback when progress reaches 100
 */
const ProgressCircle = ({ actualStatus = 0, baseSpeed = 300, onComplete }) => {
    const [displayStatus, setDisplayStatus] = useState(0);
    const timerRef = useRef(null);

    // -- Clean up timers on unmount --
    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    // -- Animate displayStatus to approach actualStatus --
    useEffect(() => {
        // Start progressing even if actualStatus is not yet available
        if (displayStatus < 100) {
            const nextSpeed = computeSpeed(displayStatus, actualStatus || 50, baseSpeed); // Assume 50% as temporary target
            timerRef.current = setTimeout(() => {
                setDisplayStatus((prev) => Math.min(prev + 1, actualStatus || 100)); // Progress until actualStatus is updated
            }, nextSpeed);
        }

        // Trigger onComplete only when displayStatus reaches 100
        if (displayStatus === 100 && onComplete) {
            onComplete();
        }
    }, [displayStatus, actualStatus, baseSpeed, onComplete]);

    const computeSpeed = (disp, actual, base) => {
        // Pause the progress if displayStatus reaches 99% and actualStatus is not yet 100
        if (disp >= 98 && actual < 100) {
            return Infinity; // Effectively pauses the ProgressCircle
        }

        // Start with base speed for initial progress
        if (actual === 0) {
            if (disp < 5) {
                return base * 2;
            }
            if (disp < 10) {
                return base * 4;
            }
            if (disp < 15) {
                return base * 8;
            }
            if (disp < 20) {
                return base * 16;
            }
            if (disp < 25) {
                return base * 32;
            }
            else { return base * 64}
        }
        // Increase speed to catch up quickly when behind the actualStatus
        if (disp < actual - 20) {
            return base / 16; // Faster speed to catch up
        }
        if (disp < actual - 15) {
            return base / 8; // Moderate speed to catch up
        }
        if (disp < actual - 10) {
            return base / 4; // Moderate speed to catch up
        }
        if (disp < actual - 5) {
            return base / 2; // Moderate speed to catch up
        }
        // Smooth the transition as it nears the actualStatus
        if (disp < actual) {
            return base;
        }
        if (disp < actual + 5) {
            return base * 2;
        }
        // Slow down if it slightly overshoots the actualStatus
        if (disp < actual + 10) {
            return base * 4; // Slightly slower
        }
        if (disp < actual + 15) {
            return base * 8; // Much slower
        }
        if (disp < actual + 20) {
            return base * 16; // Much slower
        }
        if (disp < actual + 25) {
            return base * 32; // Much slower
        }
        // Slowest speed if very far from actualStatus
        return base * 64;
    };


  // -- Circle drawing logic (based on your design) --
  const radius = 40;               // Radius of the circle
  const strokeWidth = 10;          // Width of the progress bar
  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = 2 * Math.PI * normalizedRadius;
  const strokeDashoffset =
    circumference - (displayStatus / 100) * circumference;

  const getStrokeColor = (progress) => {
    if (progress < 40) return "#f44336";  // Red for 0-40%
    if (progress < 70) return "#ff9800";  // Orange for 40-70%
    return "#4caf50";                     // Green for 70-100%
  };

  return (
    <div className="progress-circle-container">
      <svg height={radius * 2} width={radius * 2}>
        {/* Background Circle (light gray) */}
        <circle
          stroke="#e6e6e6"
          fill="transparent"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />

        {/* Foreground Progress Circle */}
        <circle
          stroke={getStrokeColor(displayStatus)}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          style={{ transition: "stroke-dashoffset 0.3s ease" }}
        />
      </svg>

      {/* Central text showing the % */}
      <div className="progress-text">{displayStatus}%</div>
    </div>
  );
};

export default ProgressCircle;

