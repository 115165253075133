import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import getAIAccount from '../../components/GetAIAccount';
import wordRange from '../../components/WordRange';
import EssayOptionsBox from '../../components/EssayOptionsBox';
import FileUpload from "../../components/FileUpload";
import DownloadOptions from "../../components/DownloadOptions";
import GreetingMessage from '../../components/greeting';
import {
    getSessionStorageItem,
    updateSessionData,
    setSessionStorageItem,
    removeSessionStorageItem,
} from "../../components/Mask";
import ProgressCircle from '../../components/ProgressCircle';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const aiAccount = getAIAccount();

function EssayWriting() {
    const [username, setUsername] = useState(getSessionStorageItem("username"));
    const [expirationDate, setExpirationDate] = useState(getSessionStorageItem("expiration_date"));
    const [writingLimit, setWritingLimit] = useState(getSessionStorageItem("writing_limit", 0));
    const [totalWords, setTotalWords] = useState(getSessionStorageItem("total_words", 0));
    const currentDate = new Date();
    const shortDate = expirationDate.substring(0, 10);
    const expiration = new Date(shortDate);
    const numericWritingLimit = Number(writingLimit);
    const numericTotalWords = Number(totalWords);

    const [collegePrompt, setCollegePrompt] = useState(getSessionStorageItem("college_prompt"));
    const [wordLimit, setWordLimit] = useState(getSessionStorageItem("word_limit", "300"));
    const [limitLower, setLimitLower] = useState(getSessionStorageItem("limit_lower", ""));
    const [keyIdea, setKeyIdea] = useState(getSessionStorageItem("key_idea"));
    const [writingIdea, setWritingIdea] = useState(getSessionStorageItem("writing_idea"));
    const [collegeIdea, setCollegeIdea] = useState(getSessionStorageItem("college_idea"));
    const [writingFramework, setWritingFramework] = useState(getSessionStorageItem("writing_framework"));
    const [draftEssay, setDraftEssay] = useState(getSessionStorageItem("draft_essay"));
    const [evaluationSuggestion, setEvaluationSuggestion] = useState(getSessionStorageItem('evaluation_suggestion'));
    const [essaySuggestion, setEssaySuggestion] = useState("");
    const [evaluationReport, setEvaluationReport] = useState(getSessionStorageItem('evaluation_report'));
    const [originalCollegeIdea, setOriginalCollegeIdea] = useState("");
    const [originalWritingFramework, setOriginalWritingFramework] = useState("");
    const [originalDraftEssay, setOriginalDraftEssay] = useState("");
    const [essayVersionIndex, setEssayVersionIndex] = useState(null);
    const [essayVersionMax, setEssayVersionMax] = useState(null);
    const [essayWritingLoading, setEssayWritingLoading] = useState(false);
    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(false);
    const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true);
    const [writingWords, setWritingWords] = useState(getSessionStorageItem("writing_words", 0));
    const [words, setWords] = useState(getSessionStorageItem("words", 0));
    const [essayWritingErrorMessage, setEssayWritingErrorMessage] = useState("");

    const [noEssayVersionMessage, setNoEssayVersionMessage] = useState("");

    const [options, setOptions] = useState({
        applyBrainstorming: false,
        applyEvaluation: false,
        finalizeEssay: false
    });

    const isWebSocketActive = useRef(false); // Initialize as false

    const [essayWritingTaskId, setEssayWritingTaskId] = useState(getSessionStorageItem('essay_writing_task_id'))
    const [essayWritingTaskRetrieved, setEssayWritingTaskRetrieved] = useState(getSessionStorageItem('essay_writing_task_retrieved'))

    // Set progress circle
    const [essayWritingProgress, setEssayWritingProgress] = useState(0);    // real status from server

    const socketRef = useRef(null);

    // Establish a WebSocket connection when starting the task or visiting the page for authenticated user
    const establishWebSocket = (taskId) => {
        const WS_BASE_URL =
            process.env.NODE_ENV === "production"
                ? "wss://api.sophoscriptor.com/ws"
                : "ws://localhost:8000/ws";

        const completeWebSocketURL = `${WS_BASE_URL}/${taskId}`;
        console.log("[DEBUG] WebSocket URL:", completeWebSocketURL);

        const socket = new WebSocket(completeWebSocketURL);
        socketRef.current = socket;

        socket.onopen = () => {
            console.log("[DEBUG] WebSocket connection opened for task_id:", taskId);
            isWebSocketActive.current = true;
        };

        socket.onmessage = async (event) => {
            console.log("[DEBUG] Raw WebSocket message:", event.data, 'task id: ', taskId);
            try {
                const message = JSON.parse(event.data);
                console.log(`[DEBUG] Task progress updated to ${message.status}. Task ID: ${taskId}`);
                setEssayWritingProgress(message.status || 10); // Default to 10 after backend task execution starts
                if (message.status === 100) {
                    console.log('poll the final results from database, task_id: ', taskId)
                    try {
                        const response = await axios.get(`${API_BASE_URL}/check_task`, {
                            params: { task_id: taskId, task_label: "essay_writing" },
                            withCredentials: true,
                        });
                        const { result } = response.data;
                        setEssayWritingErrorMessage("");
                        setEssayWritingLoading(false);
                        const effectiveOptions = options || {}; // Fallback for null or undefined options

                        updateSessionData("college_idea", result.writing_idea, setCollegeIdea);
                        if (effectiveOptions?.applyBrainstorming) {
                            updateSessionData("writing_framework", "", setWritingFramework);
                            updateSessionData("writing_idea", "", setWritingIdea);
                        }
                        if (effectiveOptions?.applyEvaluation) {
                            updateSessionData("evaluation_suggestion", "", setEvaluationSuggestion);
                        }
                        if (result.draft_essay) {
                            updateSessionData("draft_essay", result.draft_essay, setDraftEssay);
                        }
                        updateSessionData('writing_words', result.writing_words, setWritingWords);
                        updateSessionData('words', result.words, setWords);
                        updateSessionData("essayVersionIndex", result.essay_version, setEssayVersionIndex);
                        updateSessionData("essayVersionMax", result.essay_version, setEssayVersionMax);
                        setEssayVersionMax(result.essay_version)
                        console.log('essayVersionIndex: ', result.essay_version, 'essayVersionMax: ', essayVersionMax)
                        console.log('college idea: ', result.writing_idea)

                        setOriginalDraftEssay(result.draft_essay);

                        setOptions({
                            applyBrainstorming: false,
                            applyEvaluation: false,
                            finalizeEssay: false,
                            source: 'newEssay',
                        });
                        updateSessionData('essay_writing_task_retrieved', true, setEssayWritingTaskRetrieved);
                        setEssayWritingLoading(false);
                        setEssayWritingProgress(0);
                        console.log('essayVersionIndex: ', result.essay_version)

                        // Close WebSocket after task completion
                        console.log("[DEBUG] Closing WebSocket after task completion.");
                        socket.close(); // Close WebSocket connection
                        isWebSocketActive.current = false; // Update WebSocket state
                        socketRef.current = null; // Cleanup

                    } catch (error) {
                        console.error("[DEBUG] Error polling task results:", error);
                    }
                }
            } catch (err) {
                console.error("[DEBUG] WebSocket message parsing error:", err);
            }
        };

        socket.onerror = (error) => {
            console.error("[DEBUG] WebSocket error:", error);
            isWebSocketActive.current = false;
            //pollTaskStatus(); // Fallback
        };

        socket.onclose = () => {
            console.log("[DEBUG] WebSocket connection closed.");
            isWebSocketActive.current = false;
            socketRef.current = null; // Cleanup

            if (!essayWritingTaskRetrieved) {
                setTimeout(() => establishWebSocket(taskId), 2000); // Retry after 2 seconds
            }
        };

    };

    const pollTaskStatus = async () => {
        if (isWebSocketActive.current) return; // Skip polling if WebSocket is active
        if (essayWritingTaskId && !essayWritingTaskRetrieved) {
            try {
                const response = await axios.get(`${API_BASE_URL}/check_task`, {
                    params: { task_id: essayWritingTaskId, task_label: "essay_writing" },
                    withCredentials: true,
                });
                const { status, result } = response.data;
                setEssayWritingErrorMessage("");
                setEssayWritingLoading(false);
                const effectiveOptions = options || {}; // Fallback for null or undefined options

                updateSessionData("college_idea", result.writing_idea, setCollegeIdea);
                if (effectiveOptions?.applyBrainstorming) {
                    updateSessionData("writing_framework", "", setWritingFramework);
                    updateSessionData("writing_idea", "", setWritingIdea);
                }
                if (effectiveOptions?.applyEvaluation) {
                    updateSessionData("evaluation_suggestion", "", setEvaluationSuggestion);
                }
                if (result.draft_essay) {
                    updateSessionData("draft_essay", result.draft_essay, setDraftEssay);
                }
                updateSessionData('writing_words', result.writing_words, setWritingWords);
                updateSessionData('words', result.words, setWords);
                updateSessionData("essayVersionIndex", result.essay_version, setEssayVersionIndex);
                updateSessionData("essayVersionMax", result.essay_version, setEssayVersionMax);
                console.log('essayVersionIndex: ', result.essay_version, 'essayVersionMax: ', essayVersionMax)
                console.log('college idea: ', result.writing_idea)

                setOriginalDraftEssay(result.draft_essay);

                setOptions({
                    applyBrainstorming: false,
                    applyEvaluation: false,
                    finalizeEssay: false,
                    source: 'newEssay',
                });
                updateSessionData('essay_writing_task_retrieved', true, setEssayWritingTaskRetrieved);
                setEssayWritingProgress(0);
            } catch (error) {
                console.error("[DEBUG] Error polling task status:", error);
            }
        }
    };

    useEffect(() => {
        // On component mount, check if an active task exists and continue loading if needed
        if (essayWritingTaskId && !essayWritingTaskRetrieved) {
            console.log("[DEBUG] Active task detected. Resuming progress tracking...");
            setEssayWritingLoading(true); // Ensure loading spinner is active
            establishWebSocket(essayWritingTaskId); // Re-establish WebSocket
            // pollTaskStatus(); // As a fallback, ensure progress tracking via polling
        } else {
            console.log("[DEBUG] No active task or task already retrieved.");
            setEssayWritingLoading(false);
        }
    }, []); // Run only on mount

    useEffect(() => {
        // Sync `brainstormingLoading` state with task progress
        if (essayWritingProgress > 0 && essayWritingProgress < 100) {
            setEssayWritingLoading(true);
        } else if (essayWritingProgress === 100) {
            setEssayWritingLoading(false);
        }
    }, [essayWritingProgress]);

    useEffect(() => {
        const handleStorageChange = () => {
            // Update state when sessionStorage values change
            setWritingIdea(getSessionStorageItem('writing_idea'));
            setWritingFramework(getSessionStorageItem('writing_framework'));
            setEvaluationSuggestion(getSessionStorageItem('evaluation_suggestion'));
            setWordLimit(getSessionStorageItem('word_limit'));
            setLimitLower(getSessionStorageItem('limit_lower'));
        };

        // Add an event listener to detect changes in sessionStorage
        window.addEventListener('storage', handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const handleOptionChange = (newOptions) => {
        setOptions(newOptions);
        if (process.env.NODE_ENV === 'development') {
            console.log("Updated options:", newOptions); // Debugging line to check the new state
        }
    };

    // Reference to store the current AbortController
    const essayAbortController = useRef(null);

    const [initialHeight, setInitialHeight] = useState("80px");
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const ideaRef = useRef(null);
    const frameworkRef = useRef(null);
    const draftRef = useRef(null);

    // Function to calculate height based on content
    const newHeight = (text, pairedText, minHeight, maxHeight) => {
        const lineHeight = 25; // Adjust line height as needed
        const lines = Math.max(
            text.split("\n").length,
            pairedText ? pairedText.split("\n").length : 0
        );
        const calculatedHeight = Math.min(Math.max(25 + lines * lineHeight, minHeight), maxHeight);
        return `${calculatedHeight}px`;
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);

            if (ideaRef.current && draftRef.current) {
                if (isSmallScreen) {
                    // Adjust heights independently for small screens
                    ideaRef.current.style.height = newHeight(collegeIdea || "", "", 100, 500);
                    draftRef.current.style.height = newHeight(draftEssay || "", "", 200, 1000);
                } else {
                    // Adjust heights based on Essay logic
                    if (options.applyBrainstorming && writingFramework) {
                        const sharedHeight = newHeight(collegeIdea || "", writingFramework, 100, 800);
                        ideaRef.current.style.height = sharedHeight;
                        frameworkRef.current.style.height = sharedHeight;
                        setInitialHeight(sharedHeight);
                    } else {
                        // Adjust heights independently
                        ideaRef.current.style.height = newHeight(collegeIdea || "", "", 100, 800);
                        draftRef.current.style.height = newHeight(draftEssay || "", "", 200, 1000);
                    }
                }
            }
        };

        // Initial height adjustment
        handleResize();

        // Listen for window resize
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [collegeIdea, writingFramework, options.applyBrainstorming, isSmallScreen, draftEssay]);

    const handleFileLoad = (fileContent) => {
            updateSessionData("draft_essay", fileContent, setDraftEssay);
        };

    useEffect(() => {
        if (options.applyEvaluation && draftEssay) {
            updateSessionData("essay_suggestion", evaluationSuggestion, setEssaySuggestion);
        } else {
            updateSessionData("essay_suggestion", null, setEssaySuggestion);
        }
    }, [options.applyEvaluation, evaluationSuggestion]);


    useEffect(() => {
        if (options.applyBrainstorming && writingFramework && ideaRef.current && frameworkRef.current) {
            // Calculate shared height based on the content of both textareas
            const sharedHeight = newHeight(
                writingIdea || "",
                writingFramework || "",
                200, 800
            );

            // Apply the calculated shared height to both textarea
            ideaRef.current.style.height = sharedHeight;
            frameworkRef.current.style.height = sharedHeight;
            setInitialHeight(sharedHeight); // Update the initial height for dynamic resizing
        }
    }, [collegeIdea, writingIdea, writingFramework, options.applyBrainstorming]);

    useEffect(() => {
        if (wordLimit) {
            // Run the calculation and save limitLower to sessionStorage as limit_lower
            const rangeResults = wordRange(Number(wordLimit));
            setSessionStorageItem('limit_lower', rangeResults.limitLower.toString()); // Save as string
        }
    }, [wordLimit]);

    // Sync EssayVersionIndex and EssayVersionMax with sessionStorage whenever they change
    useEffect(() => {
        if (essayVersionIndex !== null) {
            setSessionStorageItem("essayVersionIndex", essayVersionIndex);
        }
        if (essayVersionMax !== null) {
            setSessionStorageItem("essayVersionMax", essayVersionMax);
        }
    }, [essayVersionIndex, essayVersionMax]);

    useEffect(() => {
        if (options.source !== 'lastVersion') {
            setNoEssayVersionMessage("")
        }
    }, [options.source]);

    // Load a specific essay version from the backend, or latest if essayVersionIndex is null
    const loadEssayVersion = async (version = null) => {
        try {
            setNoEssayVersionMessage(""); // clear any previous message
            setEssayWritingErrorMessage("");
            const params = version !== null && version !== undefined ? { version_number: version } : {};
            const response = await axios.get(`${API_BASE_URL}/get_essay_session_data`, {
                withCredentials: true, // Include cookies with the request
                params
            });

            const versionData = response.data;

            if (!versionData || versionData.essay_version === null || versionData.essay_version === "") {
                // Set message if no version data is available
                setNoEssayVersionMessage("No essay found. Start a new essay writing process.");
            } else {
                updateSessionData("college_prompt", versionData.college_prompt, setCollegePrompt);
                updateSessionData("word_limit", versionData.word_limit, setWordLimit);
                updateSessionData("college_idea", versionData.writing_idea, setCollegeIdea);
                updateSessionData("draft_essay", versionData.draft_essay, setDraftEssay);
                updateSessionData("evaluation_suggestion", versionData.evaluation_suggestion, setEvaluationSuggestion);
                updateSessionData("evaluation_report", versionData.evaluation_report, setEvaluationReport);
                updateSessionData("writing_words", versionData.writing_words, setWritingWords)
                updateSessionData("words", versionData.words, setWords)

                // Set version indices based on condition
                if (essayVersionIndex === null) {
                    updateSessionData("essayVersionIndex", versionData.essay_version, setEssayVersionIndex);
                    updateSessionData("essayVersionMax", versionData.essay_version, setEssayVersionMax);
                } else {
                    updateSessionData("essayVersionIndex", versionData.essay_version, setEssayVersionIndex);
                }
            }
            return versionData;
        } catch (error) {
            if (error.name === 'AbortError') {
                if (process.env.NODE_ENV === 'development') {
                    console.log("Essay request was aborted.");
                }
                setNoEssayVersionMessage("Essay loading interrupted. Please try again.")
            } else if (error.response && error.response.status === 401) {
                // Handle authentication error
                setNoEssayVersionMessage("You need a valid account for this service.");
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.error("Essay failed:", error);
                }
                setNoEssayVersionMessage("Error loading essay data. Please try again.")
            }
        }
    };

    // Version navigation handlers
    const handlePreviousVersion = () => {
    if (essayVersionIndex > 1 && (essayVersionIndex > essayVersionMax - 5)) {
        const newEssayIndex = essayVersionIndex - 1;
        setEssayVersionIndex(newEssayIndex);
        loadEssayVersion(newEssayIndex);
        setOptions({
            applyBrainstorming: false,
            applyEvaluation: false,
            finalizeEssay: false,
            source: 'newEssay'
        });
    }
    };

    const handleNextVersion = () => {
    if (essayVersionIndex < essayVersionMax) {
        const newEssayIndex = essayVersionIndex + 1;
        setEssayVersionIndex(newEssayIndex);
        loadEssayVersion(newEssayIndex);
        setOptions({
            applyBrainstorming: false,
            applyEvaluation: false,
            finalizeEssay: false,
            source: 'newEssay'
        });
    }
    };

    useEffect(() => {
        const storedPrompt = getSessionStorageItem("college_prompt");
        if (storedPrompt) {
            setCollegePrompt(storedPrompt);
        }
    }, []); // Run only once when the component mounts

    // Essay button handler
    const handleEssayWriting = async () => {
        setEssayWritingErrorMessage("");
        setNoEssayVersionMessage("");

        if (process.env.NODE_ENV === 'development') {
            console.log('essay writing task id: ', essayWritingTaskId, "essay writing task retrieved?", essayWritingTaskRetrieved)
        }
        if (essayWritingTaskId && !essayWritingTaskRetrieved) {
            setEssayWritingErrorMessage('A essay writing task is ongoing. Please wait for it to complete.');
            return;
        }

        // Validate input fields
        if (!collegePrompt) {
            setCollegePrompt('Some students have a background, identity, interest, or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your story.')
        }
        if (!wordLimit) {
            setEssayWritingErrorMessage(
                "Please provide the word limit."
            );
            return;
        }
        // Use writingIdea directly if applyBrainstorming is selected
        const effectiveCollegeIdea = options.applyBrainstorming ? writingIdea : collegeIdea;
        if (!effectiveCollegeIdea) {
            setEssayWritingErrorMessage(
                "Please provide your writing idea for the college application essay."
            );
            return;
        }
        setCollegeIdea(effectiveCollegeIdea);
        setEssayWritingProgress(0); // Reset lingering values
        setEssayWritingLoading(true); // Start EssayWritingLoading spinner

        // Initialize a new AbortController for the current request
        essayAbortController.current = new AbortController();
        const { signal } = essayAbortController.current;

        const rangeResults = wordRange(Number(wordLimit));

        try {
            const taskData = {
                ai_account: aiAccount || "",
                college_prompt: collegePrompt || "Some students have a background, identity, interest, or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your story.",
                word_limit: Number(wordLimit) || 300,
                writing_idea: effectiveCollegeIdea || "",
                apply_brainstorming: options.applyBrainstorming || false,
                apply_evaluation: options.applyEvaluation || false,
                finalize_essay: options.finalizeEssay || false,
                writing_framework: writingFramework || "",
                draft_essay: draftEssay || "",
                evaluation_suggestion: essaySuggestion || "",
                expansion: rangeResults.expansion || "",
                contraction: rangeResults.contraction || "",
                l01: rangeResults.l01 || "",
                u01: rangeResults.u01 || "",
                token1: rangeResults.token1 || "",
                limit_lower: Number(rangeResults.limitLower) || 0,
            }
            if (process.env.NODE_ENV === 'development') {
                console.log("task data:", taskData);
            }
            const response = await axios.post(
                `${API_BASE_URL}/start_task?task_label=essay_writing`,
                taskData,
                { withCredentials: true }
            );

            const { new_task_id } = response.data;
            setEssayWritingProgress(0);
            updateSessionData("essay_writing_task_id", new_task_id, setEssayWritingTaskId);
            updateSessionData("essay_writing_task_retrieved", false, setEssayWritingTaskRetrieved);

            establishWebSocket(new_task_id); // Immediately start WebSocket
        } catch (error) {
            console.error("[DEBUG] Error starting brainstorming task:", error);
            setEssayWritingLoading(false);
            setEssayWritingErrorMessage("Error starting the task. Please try again.");
        }
    };

    // Helper function to detect if the user has edited the input
    const isEdited = (original, current) => original !== current;

    const handleWordLimitChange = (e) => {
        let value = e.target.value;
        setWordLimit(value);
    };

    // Add validation on blur (when the input loses focus)
    const validateWordLimit = () => {
        let value = parseInt(wordLimit);

        // Validate and adjust the value only if it's a number
        if (!isNaN(value)) {
            if (value < 20) {
                value = 20; // Set to minimum if below 20
            } else if (value > 1000) {
                value = 1000; // Set to maximum if above 1000
            }
            updateSessionData("word_limit", value, setWordLimit);
            const rangeResults = wordRange(Number(wordLimit));
            updateSessionData('limit_lower', rangeResults.limitLower, setLimitLower);
        }
    }

    // Restart/Stop button handler
    const handleRestartOrStop = () => {
        if (essayWritingLoading) {
            // Abort the current essay request if it exists
            if (essayAbortController.current) {
                essayAbortController.current.abort();
                essayAbortController.current = null; // Reset after aborting
                updateSessionData('essay_writing_task_id', "", setEssayWritingTaskId) // delete current task_id
                updateSessionData('essay_writing_task_retrieved', "", setEssayWritingTaskRetrieved) // delete current task_retrieved
            }

            // Clear any loading states
            setEssayWritingLoading(false);
        } else {
            // Reset writing fields if not essay
            setCollegeIdea("");
            setWritingFramework("");
            setDraftEssay("");
            setNoEssayVersionMessage("");
            setEssayWritingErrorMessage("");
            removeSessionStorageItem("college_idea");
            removeSessionStorageItem("writing_framework");
            removeSessionStorageItem('draft_essay');
            setOptions({
                applyBrainstorming: false,
                applyEvaluation: false,
                finalizeEssay: false,
                source: "newEssay"
            });
            updateSessionData('essay_writing_task_id', "", setEssayWritingTaskId) // delete current task_id
            updateSessionData('essay_writing_task_retrieved', "", setEssayWritingTaskRetrieved) // delete current task_retrieved
        }
    };

    // for page presentation
    const [isExpanded, setIsExpanded] = useState(false);

    const togglePanel = () => {
    setIsExpanded(!isExpanded);
    };

    const [showDownloadOptions, setShowDownloadOptions] = useState(false);

    const handleEssayDownload = () => {
        setShowDownloadOptions(true);
    };

    // Effect to reset showDownloadOptions when draftEssay changes
    useEffect(() => {
        setShowDownloadOptions(false);
    }, [draftEssay]);

    const todayDate = new Date().toISOString().split('T')[0].replace(/-/g, '.');
    const defaultFileName = `Essay-${todayDate}`;

    return (
        <>
            <h1>Writing Application Essay</h1>
            {(!username || numericWritingLimit<numericTotalWords || expiration<currentDate) && <GreetingMessage />}
            <div className="accordion" style={{marginTop: "1.5em"}}>
                <div onClick={togglePanel} className="accordion-header">
                    <span>{isExpanded ? 'College Application Essay: ' : 'College Application Essay: '}</span>
                </div>
                {isExpanded && (
                    <div className="accordion-content">
                        <p style={{marginTop: "0px", marginBottom: "0px" }}>
                            Many colleges require one or more essays in their applications. While they may seem
                            burdensome, these essays offer a valuable opportunity to showcase your unique
                            qualities, experiences, and aspirations, allowing admissions committees to better
                            understand your potential contributions to their academic community.
                        </p>
                    </div>
                )}
            </div>

            <div className="textarea-container">
                <div style={{ flex: 1 }}>
                    <h3 style={{marginTop: "1em", marginBottom: "0.3em"}}>Writing Prompt</h3>
                    <textarea
                        value={collegePrompt}
                        title="Copy and paste the writing prompt from your college application here."
                        onChange={(e) => {
                            if (collegePrompt !== null && collegePrompt !== undefined) {
                                updateSessionData("college_prompt", e.target.value, setCollegePrompt);
                            }
                        }}
                        style={{
                            width: "100%",
                            height: "5em",
                            padding: "0.6em",
                            boxSizing: "border-box",
                            overflowWrap: "break-word", // Ensures text wraps within the box
                            resize: "none",             // Prevents resizing if undesired
                            verticalAlign: "top",        // Ensures text starts at the top
                            fontSize: '0.8em',
                        }}
                    />
                </div>
                <div style={{ width: '6.5em', }}>
                    <h3 style={{marginTop: "1em", marginBottom: "0.3em"}}>Word Limit</h3>
                    <input
                        type="number"
                        min="20"
                        max="1000"
                        value={wordLimit}
                        onChange={handleWordLimitChange}
                        onBlur={validateWordLimit} // Triggers validation when input loses focus
                        title="Enter an integer between 20 and 1000"
                        style={{ fontSize: '0.8em', width: "6.5em", textAlign: "center", height: "1.2em", marginTop: "0px" }} // Adjust width as needed
                    />
                </div>
            </div>

            <div className="textarea-container">
                {/* Conditionally render College Idea or replace it with Writing Idea */}
                <div className="textarea-item A">
                    <h3 style={{marginTop: "1em", marginBottom: "0.3em"}}>Writing Idea</h3>
                    <textarea
                        ref={ideaRef}
                        title="Provide your writing idea here to kick off the writing process. Highlight your unique story, identity, experiences, or passions. Incorporate dialogue and personal reflections to engage and connect with readers."
                        style={{
                            width: "100%",
                            height: initialHeight,
                            padding: "0.6em",
                            boxSizing: "border-box",
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            resize: "none",
                            fontSize: '0.8em',
                            backgroundColor: isEdited(originalCollegeIdea, collegeIdea) ? "#fff5e6" : "white"
                        }}
                        value={(options.applyBrainstorming && writingIdea) ? writingIdea : collegeIdea || ""}
                        onChange={(e) => {
                            const text = e.target.value;
                            setCollegeIdea(text);
                            updateSessionData("college_idea", text, setCollegeIdea);

                            const height = isSmallScreen
                                ? newHeight(text, "", 100, 500)
                                : newHeight(text, writingFramework, 100, 800);
                            e.target.style.height = height;
                            if (!isSmallScreen && frameworkRef.current) {
                                frameworkRef.current.style.height = height;
                            }
                        }}
                        disabled={essayWritingLoading}
                    />
                </div>

                {/* Conditionally render Writing Framework only if it has a value and being selected*/}
                {writingFramework && options.applyBrainstorming ? (
                    <div className="textarea-item B">
                        <h3 style={{marginTop: "1em", marginBottom: "0.3em"}}>Generated Writing Framework:</h3>
                        <textarea
                            ref={frameworkRef}
                            style={{
                                width: "100%",
                                height: initialHeight,
                                padding: "0.6em",
                                boxSizing: "border-box",
                                overflowY: "auto",
                                overflowWrap: "break-word",
                                resize: "none",
                                fontSize: '0.8em',
                                backgroundColor: isEdited(originalWritingFramework, writingFramework) ? "#fff5e6" : "white"
                            }}
                            value={writingFramework || ""}
                            onChange={(e) => {
                                const text = e.target.value;
                                setWritingFramework(text);
                                updateSessionData("writing_framework", text, setWritingFramework);

                                const height = isSmallScreen
                                    ? newHeight(text, "", 100, 500)
                                    : newHeight(writingIdea, text, 100, 800);
                                e.target.style.height = height;
                                if (!isSmallScreen && ideaRef.current) {
                                    ideaRef.current.style.height = height;
                                }
                            }}
                            disabled={essayWritingLoading}
                        />
                    </div>
                ) : (
                    // Hidden placeholder to maintain `frameworkRef`
                    <textarea
                        ref={frameworkRef}
                        style={{ display: 'none' }}
                    />
                )}
            </div>

            <div className="textarea-container">
                <div className="textarea-item A">
                    <h3 style={{marginTop: "1em", marginBottom: "0.3em"}}>Essay Writing Options</h3>
                    <EssayOptionsBox
                        collegePrompt={collegePrompt}
                        collegeIdea={collegeIdea}
                        writingIdea={writingIdea}
                        writingFramework={writingFramework}
                        evaluationSuggestion={evaluationSuggestion}
                        limitLower={limitLower}
                        wordLimit={wordLimit}
                        draftEssay={draftEssay}
                        options={options} // Pass the options state
                        setOptions={setOptions} // Pass the setOptions function
                        onOptionChange={handleOptionChange} // Pass the onOptionChange callback
                        loadEssayVersion={loadEssayVersion}
                    />
                    {options.source === 'fromMyComputer' && (
                        <FileUpload onFileLoad={handleFileLoad} />
                    )}
                </div>
                <div className="textarea-item A">
                    <h3 style={{marginTop: "1em", marginBottom: "0.3em"}}>Evaluation Suggestions</h3>
                    <textarea
                        style={{
                            width: "100%",
                            height: newHeight(evaluationSuggestion || "", "", 155, 400),
                            padding: "0.6em",
                            boxSizing: "border-box",
                            border: "1px solid #ddd",
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            resize: "none",
                            fontSize: '0.8em',
                            backgroundColor: "white"
                        }}
                        value={essaySuggestion || ""}
                        readOnly
                    />
                </div>
            </div>
            <div className="textarea-container">
                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        {essayWritingLoading ? ( // Show spinner when essayWritingLoading
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <ProgressCircle
                                    actualStatus={essayWritingProgress}
                                    baseSpeed={1500}   // Fastest speed in ms
                                    onComplete={() => {
                                        // If you want to do something special once it hits 100
                                        if (process.env.NODE_ENV === 'development') {
                                            console.log("Essay writing progress circle reached 100!");
                                        }
                                        setEssayWritingLoading(false); // e.g. hide spinner
                                    }}
                                />
                                <div className="spinner essayWriting">
                                    <div className="writing-spinner">
                                        <div className="pen">🖋️</div>
                                        <div className="trace"></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <button
                                onClick={handleEssayWriting}
                                className="btn-broad-action"
                                style={{backgroundColor: "#2c3e50", border: "none", color: "#ffffff", marginTop: "35px"}}
                            >
                                {options.applyBrainstorming || !draftEssay ? "Write Essay" : "Revise Essay"}
                            </button>
                        )}
                    </div>
                </div>
                <div className="textarea-item A"
                    style={{
                        display: "flex",
                        flexDirection: "column", // Stack items vertically
                        justifyContent: "center", // Center vertically
                        alignItems: "center", // Center horizontally
                        height: "100%"
                    }}
                >
                    {noEssayVersionMessage && ( // Conditionally render the message
                        <p style={{ textAlign: "center", color: "red" }}>
                            {noEssayVersionMessage}
                        </p>
                    )}
                    {essayWritingErrorMessage && (
                        <p style={{ textAlign: "center", color: "red" }}>
                            {essayWritingErrorMessage}
                        </p>
                    )}
                </div>
                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                        <button
                            onClick={handleRestartOrStop}
                            onMouseEnter={(e) => {
                                const instruction = essayWritingLoading
                                    ? "Stop the writing process."
                                    : "Start a new writing process, resetting all options to none.";
                                e.target.setAttribute("title", instruction);
                            }}
                            className="btn-broad-action" /* Apply the class here */
                            style={{marginTop: "35px"}}
                        >
                            {essayWritingLoading ? "Stop" : "Restart"} {/* Conditional label rendering */}
                        </button>
                    </div>
                </div>
            </div>

            <div className="textarea-container">
                <div className="textarea-item A">
                    <h3 style={{marginTop: '0em', marginBottom: '0.3em'}}>Sample Essay</h3>
                    <textarea
                        ref={ideaRef}
                        style={{
                            width: "100%",
                            height: newHeight(draftEssay || "", "", 200, 1000),
                            padding: "0.6em",
                            boxSizing: "border-box",
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            resize: "none",
                            fontSize: '0.8em',
                            backgroundColor: isEdited(originalDraftEssay, draftEssay) ? "#fff5e6" : "white"
                        }}
                        value={draftEssay || ""}
                        onChange={(e) => {
                            const text = e.target.value;
                            setDraftEssay(text);
                            updateSessionData("draft_essay", text, setDraftEssay);
                            e.target.style.height = newHeight(text, "", 200, 1000);
                        }}
                        disabled={essayWritingLoading}
                    />
                </div>
            </div>

            {draftEssay && writingWords && words && (
                <div className="textarea-container">
                    <p style={{marginTop: '0.3em', marginBottom: '0px'}}>{writingWords} words in the draft essay. {words} words counted for usage. </p>
                </div>
            )}

            <div className="textarea-container">
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1em', marginBottom: "1em" }}>
                        <button
                            onClick={handlePreviousVersion}
                            className="btn-record-action"
                            disabled={essayVersionIndex === 1 || essayVersionIndex <= essayVersionMax - 4}
                            style={{
                                cursor: (essayVersionIndex === 1 || essayVersionIndex <= essayVersionMax - 4) ? "not-allowed" : "pointer"
                            }}
                        >Previous Version</button>
                    </div>
                </div>
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1em', marginBottom: "1em" }}>
                        {!showDownloadOptions ? (
                            <button
                                onClick={handleEssayDownload}
                                className="btn-record-action"
                                disabled={!draftEssay || draftEssay === ""}
                                style={{ cursor: (!draftEssay || draftEssay === "") ? "not-allowed" : "pointer" }}
                            >
                                Download the Essay
                            </button>
                        ) : (
                            <DownloadOptions writingPiece={draftEssay} defaultFileName={defaultFileName} />
                        )}
                    </div>
                </div>
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1em', marginBottom: "1em" }}>
                        <button
                            onClick={handleNextVersion}
                            className="btn-record-action"
                            disabled={essayVersionIndex >= essayVersionMax}
                            style={{ cursor: essayVersionIndex >= essayVersionMax ? "not-allowed" : "pointer" }}
                        >Next Version</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EssayWriting;
