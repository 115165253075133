//frontend/src/Sidebar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AuthContainer from './components/Authentication';
import { jwtDecode } from "jwt-decode";
import logo from './assets/images/icon01-192X192.png';
import { useAppState } from './components/AppContext';

const Sidebar = ({ isOpen, onTokenChange, onLinkClick, hideHeader }) => {
    const { updateToken } = useAppState();
    const [token, setToken] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    const [isScreenSmall, setIsScreenSmall] = useState(false);

    useEffect(() => {
        // Function to check screen size
        const checkScreenSize = () => {
            setIsScreenSmall(window.innerWidth < 1300);
        };

        // Add event listener to track screen resize
        window.addEventListener('resize', checkScreenSize);

        // Initial check
        checkScreenSize();

        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);


    const handleTokenChange = (newToken) => {
        setToken(newToken);
        updateToken(newToken);
        if (newToken) {
            const decodedToken = jwtDecode(newToken);
            setUserInfo(decodedToken);
        } else {
            setUserInfo(null);
        }
    };

    return (
        <>
            <div className={`Sidebar ${isOpen ? 'open' : ''}`}>
                {!hideHeader && (
                    <div className="Sidebar-header">
                        <img src={logo} alt="SophoScriptor Logo" className="Sidebar-logo" />
                        <h2 className="Sidebar-title">SophoScriptor</h2>
                    </div>
                )}
                {hideHeader && (
                    <div className="Sidebar-animation">
                        <div className="laptop">
                            <div className="laptop-screen">
                                <div className="typing-container">
                                    <span className="typing-line1">SophoScriptor</span>
                                    <span className="typing-line2">Creativity</span>
                                    <span className="typing-line3">Authentic Self</span>
                                </div>
                            </div>
                        </div>
                        <div className="laptop-keyboard"></div>
                    </div>
                )}
                <nav>
                    <Link className="Sidebar-link" to="/Homepage" onClick={onLinkClick} title={isScreenSmall ? "Welcome" : ""}>
                        Welcome
                    </Link>
                    <Link className="Sidebar-link" to="/WritingAssistance" onClick={onLinkClick} title={isScreenSmall ? "Writing Assistance" : ""}>
                        Writing Assistance
                    </Link>
                    <Link className="Sidebar-link" to="/ProductsServices" onClick={onLinkClick} title={isScreenSmall ? "Products & Services" : ""}>
                        Products & Services
                    </Link>
                    <Link className="Sidebar-link" to="/HelpSupport" onClick={onLinkClick} title={isScreenSmall ? "Help & Support" : ""}>
                        Help & Support
                    </Link>
                    <Link className="Sidebar-link" to="/Account" onClick={onLinkClick} title={isScreenSmall ? "Account" : ""}>
                        Account
                    </Link>
                    <hr className="HorizontalRule" style={{marginTop: "3em", marginBottom: "15px"}}/>
                    <AuthContainer onTokenChange={handleTokenChange} />
                    <p style={{marginBottom: "3em"}}></p>
                </nav>
            </div>
        </>
    );
};

export default Sidebar;



