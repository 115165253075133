// frontend/src/subHomepage/Welcome.js
import React from 'react';
import '../../App.css';
import GreetingMessage from '../../components/greeting';
import CouponAnnouncement from "../../components/CouponAnnouncement";
import VideoPlaceholder from "../../components/VideoPlaceholder";

const Welcome = () => {
    return (
        <>
            <div className="App-header">
                <h2>Welcome to SophoScriptor</h2>
                <h4>Your Personal Writing Assistant</h4>
                <p>
                    Spark <span className="highlighted-text">Creativity</span> and Discover Your <span className="highlighted-text">Authentic Self</span>
                </p>
            </div>
            <div style={{
                padding: "2em",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <GreetingMessage />
                <CouponAnnouncement />
                <VideoPlaceholder />
            </div>
        </>
    );
};

export default Welcome;

