import React from 'react';
import '../App.css';

function CancelPage() {
    return (
        <>
            <div className="Page-header" >
            </div>

            <div className="Page-content">
                <h1 style={{color: "#e5533c"}}>Payment Canceled</h1>
                <div className='NoticeBoard' style={{borderColor: "#ff6347"}}>
                    <p>Your payment process was not completed. </p>
                    <p style={{marginTop: "20px"}}>If this was a mistake, you can try again. Otherwise, feel free to continue exploring the site. </p>
                </div>
            </div>
        </>
    );
}

export default CancelPage;
