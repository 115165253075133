import React, { useState, useEffect } from 'react';
import { useAccountInfo } from '../../components/UserAccountInfo';
import { getSessionStorageItem, updateSessionData } from "../../components/Mask";

const UserAccountProfile = () => {
    // State variables for user account information
    const [username, setUsername] = useState(getSessionStorageItem("username") || "");
    const [nickname, setNickname] = useState(getSessionStorageItem("nickname") || "");
    const [email, setEmail] = useState(getSessionStorageItem("email") || "");
    const [firstname, setFirstname] = useState(getSessionStorageItem("firstname") || "");
    const [lastname, setLastname] = useState(getSessionStorageItem("lastname") || "");
    const [myTimezone, setMyTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC');
    const [countryCode, setCountryCode] = useState(getSessionStorageItem("country_code") || "");
    const [country, setCountry] = useState(getSessionStorageItem("country") || "");
    const [phoneNumber, setPhoneNumber] = useState(getSessionStorageItem("phone_number") || "");
    const [accountMFA, setAccountMFA] = useState(getSessionStorageItem("account_mfa") || "");
    const [mfaMethod, setMfaMethod] = useState(getSessionStorageItem("mfa_method") || "");
    const [role, setRole] = useState(getSessionStorageItem("role") || "");
    const [quota, setQuota] = useState(getSessionStorageItem("quota") || "");
    const [lastTransactionDate, setLastTransactionDate] = useState(getSessionStorageItem("last_transaction_date") || "");
    const [membership, setMembership] = useState(getSessionStorageItem('membership') || "");
    const [expirationDate, setExpirationDate] = useState(getSessionStorageItem('expiration_date') || "");
    const [writingLimit, setWritingLimit] = useState(getSessionStorageItem('writing_limit') || "");
    const [totalWords, setTotalWords] = useState(getSessionStorageItem('total_words') || "");
    const [authenticationStatus, setAuthenticationStatus] = useState(getSessionStorageItem("authentication_status") || "");

    const accountInfo = useAccountInfo();

    useEffect(() => {
        if (accountInfo) {
            updateSessionData("username", accountInfo.username, setUsername);
            updateSessionData("nickname", accountInfo.nickname, setNickname);
            updateSessionData("email", accountInfo.email, setEmail);
            updateSessionData("firstname", accountInfo.firstname, setFirstname);
            updateSessionData("lastname", accountInfo.lastname, setLastname);
            updateSessionData("country_code", accountInfo.country_code, setCountryCode);
            updateSessionData("country", accountInfo.country, setCountry);
            updateSessionData("phone_number", accountInfo.phone_number, setPhoneNumber);
            updateSessionData("account_mfa", accountInfo.account_mfa, setAccountMFA);
            updateSessionData("mfa_method", accountInfo.mfa_method, setMfaMethod);
            updateSessionData("role", accountInfo.role, setRole);
            updateSessionData("quota", accountInfo.quota, setQuota);
            updateSessionData("last_transaction_date", accountInfo.last_transaction_date, setLastTransactionDate);
            updateSessionData("membership", accountInfo.membership, setMembership);
            updateSessionData("expiration_date", accountInfo.expiration_date, setExpirationDate);
            updateSessionData("writing_limit", accountInfo.writing_limit, setWritingLimit);
            updateSessionData("total_words", accountInfo.total_words, setTotalWords);
            updateSessionData("authentication_status", accountInfo.authentication_status, setAuthenticationStatus);
        }
    }, [accountInfo]);

    const currentDate = new Date();
    const shortExpirationDate = expirationDate.substring(0, 10);
    const expiration = new Date(shortExpirationDate);
    const shortLastTransactionDate = lastTransactionDate.substring(0, 10);

    let noticeMessage = "";
    if (!username) {
        noticeMessage = "Please sign up for a membership, or log in your account, to use the service.";
    } else if (username && !authenticationStatus) {
        if (totalWords >= writingLimit) {
            noticeMessage = "You have exceeded the writing limit of your account. Please purchase a new writing plan or writing limit extension to continue using the service.";
        } else if (expiration <= currentDate) {
            noticeMessage = "Your account has expired. Please purchase a new writing plan or expiration date extension to continue using the service.";
        }
    }

    const handleUpdateClick = () => {
        window.location.reload();
    };

    return (
        <>
            <h1>Your Profile</h1>
            {noticeMessage && <p style={{ color: 'red' }}>{noticeMessage}</p>}
            <div className="textarea-container">
                <div className="textarea-item B">
                    <h2>Account Settings</h2>
                    <div className="textarea-container">
                        <div className="textarea-item A">
                            <p><strong>Username:</strong> {username}</p>
                            <p><strong>Nickname:</strong> {nickname}</p>
                            <p><strong>Email:</strong> {email}</p>
                            <p><strong>First Name:</strong> {firstname}</p>
                            <p><strong>Last Name:</strong> {lastname}</p>
                        </div>
                        <div className="textarea-item D">
                            <p><strong>Phone Number:</strong> {countryCode} {phoneNumber}</p>
                            <p><strong>Country:</strong> {country}</p>
                            <p><strong>Timezone:</strong> {myTimezone}</p>
                            <p><strong>Account MFA:</strong> {accountMFA ? "Enabled" : "Disabled"}</p>
                            {accountMFA && <p><strong>MFA Method:</strong> {mfaMethod} </p>}

                        </div>
                    </div>
                </div>
                <div className="textarea-item A">
                    <h2>Membership Details</h2>
                    <div className="textarea-container">
                        <div className="textarea-item A">
                            <p><strong>Role:</strong> {role}</p>
                            <p><strong>Membership Type:</strong> {membership}</p>
                            <p><strong>Expiration Date:</strong> {shortExpirationDate}</p>
                            <p><strong>Quota:</strong> {quota}</p>
                            <p><strong>Writing Limit:</strong> {writingLimit}</p>
                            <p><strong>Total Words:</strong> {totalWords}</p>
                            <p><strong>Last Transaction Date:</strong> {shortLastTransactionDate}</p>
                        </div>
                    </div>
                </div>
            </div>
            <button
                className = 'btn-record-action'
                style={{ marginTop: "40px", marginBottom: "20px" }}
                onClick={handleUpdateClick}>Update Account Info</button>
        </>
    );
};

export default UserAccountProfile;



